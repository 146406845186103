// SearchStoryList.js
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import fetchMessageSearch from '../action/fetchMessageSearch.js';
import fetchFeeds from '../action/fetchFeeds.js';
import getOperationData from '../selector/getOperationData.js';
import getListData from '../selector/getListData.js';
import getMeData from '../selector/getMeData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import { getSelectedQueryOptionObject } from '../selector/getFeedQueryObject.js';
import getSelectedFeedQueryOptions from '../selector/getSelectedFeedQueryOptions.js';
import SearchStoryList from '../component/SearchStoryList.jsx';
import withSearchQuery from '../component/WithSearchQuery.jsx';

const mapStateToProps = (state, { query }) => {
  const { sorts: sortings } = getSelectedQueryOptionObject(
    state,
    '',
    getSelectedFeedQueryOptions(state, 'SEARCH_MESSAGE', undefined)
  );
  const sortingString = sortings.join('&');

  const hasQuery = !!query;

  const defaultCategory = getOperationData(
    state,
    ['recommendStoryCategory'],
    'search'
  );

  const searchSelectPath = ['search', 'message', 'story', query];
  const defaultSelectPath = ['home', 'messages', defaultCategory];
  const selectPath = hasQuery ? searchSelectPath : defaultSelectPath;

  const resultIds = getListData(state, searchSelectPath, 'itemIds');
  const defaultIds = getListData(state, defaultSelectPath, 'itemIds');

  const deviceWidth = getOperationData(state, ['device'], 'width');
  const storyRenderAmount = getOperationData(
    state,
    ['search'],
    'storyRenderAmount'
  );

  const nextPage = getListData(state, selectPath, 'nextPage');
  const isNextPageFetching = getNetworkingData(
    state,
    [...selectPath, nextPage],
    'isFetching'
  );

  return {
    sortingString,
    query,
    defaultCategory,
    deviceWidth,
    storyRenderAmount,
    nextPage,
    hasQuery,
    isAuthed: !!getMeData(state, 'id'),
    isNextPageFetching,
    resultIds,
    defaultIds,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFeeds: data => dispatch(fetchFeeds(data)),
    fetchMessageSearch: debounce(
      conditions => dispatch(fetchMessageSearch(conditions)),
      500 // TODO: remote config
    ),
  };
};

export default withSearchQuery(
  connect(mapStateToProps, mapDispatchToProps)(SearchStoryList)
);
