// SearchSwaggerList.js
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import fetchFeeds from '../action/fetchFeeds.js';
import fetchUserSearch from '../action/fetchUserSearch.js';
import getOperationData from '../selector/getOperationData.js';
import getMeData from '../selector/getMeData.js';
import getListData from '../selector/getListData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import { getSelectedQueryOptionObject } from '../selector/getFeedQueryObject.js';
import getSelectedFeedQueryOptions from '../selector/getSelectedFeedQueryOptions.js';
import SearchSwaggerList from '../component/SearchSwaggerList.jsx';
import withSearchQuery from '../component/WithSearchQuery.jsx';

import subscribeFeedChannel from '../action/subscribeFeedChannel.js';
import unsubscribeFeedChannel from '../action/unsubscribeFeedChannel.js';

import getIsRemoteConfigMerged from '../selector/getIsRemoteConfigMerged.js';

import {
  CONFIG_PRIORITY_CONFIGURE,
  CONFIG_PRIORITY_PRESENCE_USER,
  CONFIG_PRIORITY_PRESENCE_CLIENT,
} from '../resource/configPriority.js';

const mapStateToProps = (state, { category, query }) => {
  const { sorts: sortings } = getSelectedQueryOptionObject(
    state,
    '',
    getSelectedFeedQueryOptions(state, 'SEARCH_USER', undefined)
  );
  const sortingString = sortings.join('&');

  const hasQuery = !!query;
  const swaggerOnlineFeed = getOperationData(
    state,
    ['swaggerOnlineFeed'],
    'search'
  );
  const defaultCategory = category || swaggerOnlineFeed;
  const searchSelectPath = ['search', 'user', query];
  const defaultSelectPath = ['home', 'users', defaultCategory];
  const selectPath = hasQuery ? searchSelectPath : defaultSelectPath;

  const resultIds = getListData(state, searchSelectPath, 'itemIds');
  const defaultIds = getListData(state, defaultSelectPath, 'itemIds');

  const nextPage = getListData(state, selectPath, 'nextPage');
  const isNextPageFetching = getNetworkingData(
    state,
    [...selectPath, nextPage],
    'isFetching'
  );

  const isAuthed = !!getMeData(state, 'id');
  const isRemoteConfigMerged = isAuthed
    ? getIsRemoteConfigMerged(state, CONFIG_PRIORITY_PRESENCE_USER)
    : getIsRemoteConfigMerged(state, CONFIG_PRIORITY_PRESENCE_CLIENT) ||
      getIsRemoteConfigMerged(state, CONFIG_PRIORITY_CONFIGURE);

  return {
    sortingString,
    query,
    category,
    defaultCategory,
    nextPage,
    hasQuery,
    isNextPageFetching,
    resultIds,
    defaultIds,
    isRemoteConfigMerged,
    swaggerOnlineFeed,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFeeds: data => dispatch(fetchFeeds(data)),
    fetchUserSearch: debounce(
      conditions =>
        dispatch(
          fetchUserSearch({
            ...conditions,
            selectPath: ['search', 'user', conditions.query],
          })
        ),
      500 // TODO: remote config
    ),
    subscribeFeedChannel: ({ feedName }) =>
      dispatch(subscribeFeedChannel({ feedName })),
    unsubscribeFeedChannel: ({ feedName }) =>
      dispatch(unsubscribeFeedChannel({ feedName })),
  };
};

export default withSearchQuery(
  connect(mapStateToProps, mapDispatchToProps)(SearchSwaggerList)
);
