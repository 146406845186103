// fetchUserSearch.js
'use strict';
import parseLinkHeader from 'parse-link-header';
import debounce from 'lodash/debounce';

import fetch from '../resource/customFetch.js';
import pushToastr from '../action/pushToastr.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';
import objectifyArrayById from '../resource/objectifyArrayById.js';
import getMeData from '../selector/getMeData.js';
import {
  ADD_USERS,
  ADD_LIST_ITEMS,
  SET_LIST_ITEMS,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

let debounceFunction;

export const fetchUserSearch = payload => async dispatch => {
  if (!debounceFunction) {
    debounceFunction = debounce(
      payload => dispatch(fetchUserSearchCore(payload)),
      500 // TODO: remote config
    );
  }
  return debounceFunction(payload);
};

/**
 * Fetch user search
 * @kind action
 * @param {string} { v } - API version.
 * @param {string} { query } - search query.
 * @param {string} { queryUrl } - completed search url.
 * @param {number} { limit } - page size.
 * @param {number} { creator } - 1: search CPs only / 0: search users only.
 * @param {string} { index } - support user/message/hashtag searching.
 * @param {array} { [sortings = []] } - sortings.
 * @param {object} { [httpProxyHeaders = {}] } - http proxy headers for SSR.
 * @return {Promise} Action promise.
 */
export const fetchUserSearchCore =
  ({
    v = '2',
    query,
    queryUrl,
    limit = 25,
    creator = 1,
    index = 'user',
    selectPath: listSelectPath = [],
    sortings = [],
    httpProxyHeaders = {},
  }) =>
  async (dispatch, getState) => {
    const state = getState();
    const token = getMeData(state, 'token');

    const headers = new Headers({
      ...getHeaders(),
      ...httpProxyHeaders,
    });
    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }

    const selectPath = [...listSelectPath, queryUrl || 'firstPage'];

    let url;

    if (queryUrl) {
      try {
        url = new URL(queryUrl);
      } catch (error) {
        return dispatch(
          pushToastr({ textKey: 'something_went_wrong', color: 'error' })
        );
      }
    } else {
      url = getResourceUrl({ endpoint: '/search' });
      url.searchParams.append('v', v);
      url.searchParams.append('q', query);
      url.searchParams.append('index', index);
      url.searchParams.append('creator', creator);
      url.searchParams.append('limit', limit);
      if (sortings.length)
        sortings.forEach(sorting =>
          url.searchParams.append('sorting', sorting)
        );
    }

    const fetchOptions = {
      headers,
    };

    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });

    try {
      let response = await fetch(url.href, fetchOptions);

      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl: url,
        });
      }

      const links = parseLinkHeader(response.headers.get('Link'));
      const nextPage = links?.next?.url;

      const payload = await response.json();
      const users = objectifyArrayById({ array: payload });
      const itemIds = Object.keys(users);

      dispatch({ type: ADD_USERS, payload: { users } });

      const listActionType = queryUrl ? ADD_LIST_ITEMS : SET_LIST_ITEMS;
      dispatch({
        type: listActionType,
        payload: {
          selectPath: listSelectPath,
          itemIds,
          nextPage,
        },
      });

      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default fetchUserSearch;
