// SearchAll.jsx
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import SearchFlixList from '../container/SearchFlixList.js';
import SearchSwaggerList from '../container/SearchSwaggerList.js';
import SearchStoryList from '../container/SearchStoryList.js';

import Loading from '../component/Loading.jsx';

import { TranslationNamespace } from '../resource/translationNamespace.js';

import {
  textColor,
  searchStoryMessageCardWidth,
  searchStoryMessageCardsColumnGap,
  searchStoryMessageCardsHorizontalTotalPadding,
} from '../style/variables.js';

import EmptyResultIconResource from '../../img/img-empty-nofollowing.svg';

const STORY_FETCH_MINIMUM_AMOUNT = 42; // TODO: remote config
const STORY_FETCH_MAXIMUM_AMOUNT = 100; // TODO: remote config

export class SearchAll extends React.PureComponent {
  nextTick = null;
  setStoryRenderAmount = () => {
    const { deviceWidth, storyRenderAmount, setStoryRenderAmount } = this.props;
    if (!deviceWidth) {
      return setStoryRenderAmount({ amount: storyRenderAmount });
    }
    // device width = container padding + (n * message card width) + ((n - 1) * message card horizontal gap)
    const rowLimit =
      (deviceWidth -
        searchStoryMessageCardsHorizontalTotalPadding +
        searchStoryMessageCardsColumnGap) /
      (searchStoryMessageCardWidth + searchStoryMessageCardsColumnGap);
    return setStoryRenderAmount({ amount: Math.floor(rowLimit) * 2 });
  };
  fetchStory = () => {
    const {
      query,
      defaultStoryCategory,
      storyRenderAmount,
      hasQuery,
      isActive,
      fetchMessageSearch,
      fetchFeeds,
      isRemoteConfigMerged,
    } = this.props;
    if (!storyRenderAmount || !isActive) {
      return;
    }
    if (hasQuery) {
      return fetchMessageSearch({
        query,
        type: 'story',
        limit: Math.min(
          STORY_FETCH_MAXIMUM_AMOUNT,
          Math.max(storyRenderAmount, STORY_FETCH_MINIMUM_AMOUNT)
        ),
      });
    }
    if (isRemoteConfigMerged && defaultStoryCategory) {
      return fetchFeeds({
        type: defaultStoryCategory,
        limit: Math.min(
          STORY_FETCH_MAXIMUM_AMOUNT,
          Math.max(storyRenderAmount, STORY_FETCH_MINIMUM_AMOUNT)
        ),
      });
    }
    return;
  };
  fetchFlix = () => {
    const {
      query,
      defaultFlixCategory,
      hasQuery,
      isActive,
      fetchMessageSearch,
      fetchFeeds,
      isRemoteConfigMerged,
    } = this.props;
    if (!isActive) {
      return;
    }
    if (hasQuery) {
      return fetchMessageSearch({
        query,
        type: 'flix',
      });
    }
    if (isRemoteConfigMerged && defaultFlixCategory) {
      return fetchFeeds({
        type: defaultFlixCategory,
      });
    }
    return;
  };
  fetchSwagger = () => {
    const {
      query,
      defaultSwaggerCategory,
      hasQuery,
      isActive,
      fetchUserSearch,
      fetchFeeds,
      isRemoteConfigMerged,
    } = this.props;
    if (!isActive) {
      return;
    }
    if (hasQuery) {
      return fetchUserSearch({
        query,
      });
    }
    if (isRemoteConfigMerged && defaultSwaggerCategory) {
      return fetchFeeds({
        type: defaultSwaggerCategory,
      });
    }
    return;
  };
  fetchHashtag = () => {
    const { query, isActive, fetchHashtagSearch } = this.props;
    if (!isActive) {
      return;
    }
    return fetchHashtagSearch({
      query,
    });
  };
  componentDidMount() {
    this.nextTick = setTimeout(() => {
      const { isFlixFetched, isSwaggerFetched, isStoryFetched } = this.props;
      if (!isFlixFetched) {
        this.fetchFlix();
      }
      if (!isStoryFetched) {
        this.fetchStory();
      }
      if (!isSwaggerFetched) {
        this.fetchSwagger();
      }
      this.setStoryRenderAmount();
    });
  }
  componentDidUpdate(prevProps) {
    const {
      query,
      defaultFlixCategory,
      defaultStoryCategory,
      defaultSwaggerCategory,
      storyIds,
      storyRenderAmount,
      deviceWidth,
      hasStoryNextPage,
      isAuthed,
      isActive,
      isRemoteConfigMerged,
    } = this.props;

    const isAuthOrQueryOrIsActiveChanged =
      isAuthed !== prevProps.isAuthed ||
      query !== prevProps.query ||
      isActive !== prevProps.isActive ||
      isRemoteConfigMerged !== prevProps.isRemoteConfigMerged;

    const shouldFetchFlix =
      isAuthOrQueryOrIsActiveChanged ||
      defaultFlixCategory !== prevProps.defaultFlixCategory;
    if (shouldFetchFlix) {
      this.fetchFlix();
    }

    const shouldFetchSwagger =
      isAuthOrQueryOrIsActiveChanged ||
      defaultSwaggerCategory !== prevProps.defaultSwaggerCategory;
    if (shouldFetchSwagger) {
      this.fetchSwagger();
    }

    const shouldFetchStory =
      isAuthOrQueryOrIsActiveChanged ||
      defaultStoryCategory !== prevProps.defaultStoryCategory ||
      (storyRenderAmount > prevProps.storyRenderAmount &&
        storyRenderAmount > storyIds.length &&
        hasStoryNextPage);
    if (shouldFetchStory) {
      this.fetchStory();
    }

    const shouldFetchHashtag = isAuthOrQueryOrIsActiveChanged;
    if (shouldFetchHashtag) {
      this.fetchHashtag();
    }

    if (deviceWidth !== prevProps.deviceWidth) {
      this.setStoryRenderAmount();
    }
  }
  componentWillUnmount() {
    clearTimeout(this.nextTick);
  }
  render() {
    const {
      type,
      isFlixFetched,
      isSwaggerFetched,
      isStoryFetched,
      flixIds,
      swaggerIds,
      storyIds,
      t,
    } = this.props;
    const isLoading = !isFlixFetched || !isSwaggerFetched || !isStoryFetched;
    const isEmpty =
      flixIds.length === 0 && swaggerIds.length === 0 && storyIds.length === 0;

    const LoadingElement = (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
    if (isEmpty) {
      if (isLoading) {
        // only show loading when list is empty to prevent scroll height loss.
        return <StyledSearchAll>{LoadingElement}</StyledSearchAll>;
      }
      return (
        <StyledSearchAll>
          <EmptyResultWrapper>
            <EmptyResultIcon src={EmptyResultIconResource} />
            {t('search_no_result', {
              ns: TranslationNamespace.SEARCH,
            })}
          </EmptyResultWrapper>
        </StyledSearchAll>
      );
    }
    return (
      <StyledSearchAll>
        <SearchFlixList type={type} isOnSearchAll />
        <SearchSwaggerList type={type} isOnSearchAll />
        <SearchStoryList type={type} isOnSearchAll />
      </StyledSearchAll>
    );
  }
}

SearchAll.propTypes = {
  query: PropTypes.string,
  type: PropTypes.string,
  defaultFlixCategory: PropTypes.string,
  defaultSwaggerCategory: PropTypes.string,
  defaultStoryCategory: PropTypes.string,
  storyRenderAmount: PropTypes.number,
  deviceWidth: PropTypes.number,
  isAuthed: PropTypes.bool,
  isFlixFetched: PropTypes.bool,
  isSwaggerFetched: PropTypes.bool,
  isStoryFetched: PropTypes.bool,
  hasStoryNextPage: PropTypes.bool,
  hasQuery: PropTypes.bool,
  isActive: PropTypes.bool,
  flixIds: PropTypes.array,
  swaggerIds: PropTypes.array,
  storyIds: PropTypes.array,
  fetchFeeds: PropTypes.func,
  fetchMessageSearch: PropTypes.func,
  fetchUserSearch: PropTypes.func,
  fetchHashtagSearch: PropTypes.func,
  setStoryRenderAmount: PropTypes.func,
  t: PropTypes.func.isRequired,
  isRemoteConfigMerged: PropTypes.bool,
};

SearchAll.defaultProps = {
  query: '',
  type: '',
  defaultFlixCategory: '',
  defaultSwaggerCategory: '',
  defaultStoryCategory: '',
  storyRenderAmount: 0,
  deviceWidth: 0,
  isAuthed: false,
  isFlixFetched: false,
  isSwaggerFetched: false,
  isStoryFetched: false,
  hasStoryNextPage: false,
  hasQuery: false,
  isActive: false,
  flixIds: [],
  swaggerIds: [],
  storyIds: [],
  fetchFeeds: () => null,
  fetchMessageSearch: () => null,
  fetchUserSearch: () => null,
  fetchHashtagSearch: () => null,
  setStoryRenderAmount: () => null,
  isRemoteConfigMerged: false,
  subscribeFeedChannel: () => null,
  unsubscribeFeedChannel: () => null,
};

const StyledSearchAll = styled.div``;

const LoadingWrapper = styled.div`
  margin-top: 52px;
`;

const EmptyResultWrapper = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${textColor.black};
  font-size: 14px;
`;

const EmptyResultIcon = styled.img`
  margin-bottom: 12px;
  height: 120px;
  width: auto;
`;

export default withTranslation()(SearchAll);
